/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
// assets
import facebook from '../../content/assets/social/facebook.png'
import twitter from '../../content/assets/social/twitter.png'
import linkedin from '../../content/assets/social/linkedin.png'

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const social = {
    linkedin: {link: `https://www.linkedin.com/in/ashish-kanwar-singh-5a5029105/`, logo: linkedin},
    facebook: {link: `https://www.facebook.com/ashish.ks.7`, logo: facebook},
    twitter: {link: `https://twitter.com/ashishks55`, logo: twitter}
  }
  
  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div>
        <div><strong>By {author}</strong></div>
        <div
          style={{
            color: `grey`,
            fontSize: `13px`,
            marginTop: `0px`
          }}
        >
          Building products that matter<br/>
          Senior Software Engineer <a href="https://xanadu.ai/" target="_blank" rel="noopener noreferrer" style={{boxShadow: '0 0px 0 0 white'}}>@XanaduAI</a>
        </div>
        <div
          style={{
            marginTop: `10px`
          }}
        >
        {Object.entries(social).map(([key, value]) => (
          <a key={key} href={value.link} target="_blank" rel="noopener noreferrer"
              style={{
                color: `#fff`,
                margin: `5px 10px 5px 0px`
              }}
            >
            <img src={value.logo} width="30px" alt={key}/>
          </a>
        ))}
        </div>
      </div>
    </div>
  )
}

export default Bio
